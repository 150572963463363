export const Shiyu7_1 = [
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 13.99,
    rank: 1,
    avg_round: 76,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 8.65,
    rank: 2,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 8.58,
    rank: 3,
    avg_round: 65,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 5.12,
    rank: 4,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 4.84,
    rank: 5,
    avg_round: 68,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 3.46,
    rank: 6,
    avg_round: 73,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 2.96,
    rank: 7,
    avg_round: 68,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 2.91,
    rank: 8,
    avg_round: 76,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 2.31,
    rank: 9,
    avg_round: 87,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 2.0,
    rank: 10,
    avg_round: 76,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 1.88,
    rank: 11,
    avg_round: 49,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 1.67,
    rank: 12,
    avg_round: 76,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 1.57,
    rank: 13,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 1.34,
    rank: 14,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.33,
    rank: 15,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 1.22,
    rank: 16,
    avg_round: 48,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.22,
    rank: 16,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 1.21,
    rank: 18,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 1.17,
    rank: 19,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 1.09,
    rank: 20,
    avg_round: 165,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.98,
    rank: 21,
    avg_round: 79,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.95,
    rank: 22,
    avg_round: 71,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.9,
    rank: 23,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.78,
    rank: 24,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.72,
    rank: 25,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.71,
    rank: 26,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.64,
    rank: 27,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.62,
    rank: 28,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.6,
    rank: 29,
    avg_round: 167,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.55,
    rank: 30,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.55,
    rank: 30,
    avg_round: 65,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.55,
    rank: 30,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.55,
    rank: 30,
    avg_round: 50,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.52,
    rank: 34,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'nicole-demara',
    app_rate: 0.5,
    rank: 35,
    avg_round: 80,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.48,
    rank: 36,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.45,
    rank: 37,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.45,
    rank: 37,
    avg_round: 67,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.38,
    rank: 39,
    avg_round: 163,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'soukaku',
    app_rate: 0.34,
    rank: 40,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.34,
    rank: 40,
    avg_round: 149,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.34,
    rank: 40,
    avg_round: 90,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.33,
    rank: 43,
    avg_round: 77,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.33,
    rank: 43,
    avg_round: 88,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.33,
    rank: 43,
    avg_round: 76,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.31,
    rank: 46,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.31,
    rank: 46,
    avg_round: 88,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.29,
    rank: 48,
    avg_round: 63,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'soukaku',
    app_rate: 0.29,
    rank: 48,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.29,
    rank: 48,
    avg_round: 70,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.28,
    rank: 51,
    avg_round: 75,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.28,
    rank: 51,
    avg_round: 75,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.28,
    rank: 51,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.26,
    rank: 54,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lycaon',
    app_rate: 0.26,
    rank: 54,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.24,
    rank: 56,
    avg_round: 84,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.24,
    rank: 56,
    avg_round: 69,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.24,
    rank: 56,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.24,
    rank: 56,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.22,
    rank: 60,
    avg_round: 156,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.21,
    rank: 61,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.19,
    rank: 62,
    avg_round: 91,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.19,
    rank: 62,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.19,
    rank: 62,
    avg_round: 88,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 62,
    avg_round: 67,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'astra-yao',
    app_rate: 0.17,
    rank: 66,
    avg_round: 72,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.16,
    rank: 67,
    avg_round: 169,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.16,
    rank: 67,
    avg_round: 173,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.16,
    rank: 67,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'lycaon',
    app_rate: 0.16,
    rank: 67,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 71,
    avg_round: 156,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 71,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 71,
    avg_round: 85,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.14,
    rank: 71,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.14,
    rank: 71,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 76,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.12,
    rank: 76,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 76,
    avg_round: 193,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.12,
    rank: 76,
    avg_round: 226,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'soukaku',
    app_rate: 0.12,
    rank: 76,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'yanagi',
    app_rate: 0.12,
    rank: 76,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.1,
    rank: 82,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 82,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 82,
    avg_round: 63,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 82,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 82,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 82,
    avg_round: 76,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 82,
    avg_round: 71,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 82,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 82,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.1,
    rank: 82,
    avg_round: 220,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 92,
    avg_round: 121,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 92,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 92,
    avg_round: 69,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 92,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 92,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 92,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.09,
    rank: 92,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 92,
    avg_round: 191,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 92,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 92,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 92,
    avg_round: 76,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 92,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.09,
    rank: 92,
    avg_round: 162,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 105,
    avg_round: 62,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 105,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 105,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 105,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 105,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 105,
    avg_round: 174,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lycaon',
    app_rate: 0.07,
    rank: 105,
    avg_round: 73,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 105,
    avg_round: 178,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 113,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 113,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 113,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 113,
    avg_round: 155,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'qingyi',
    app_rate: 0.05,
    rank: 113,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 113,
    avg_round: 189,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 113,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.05,
    rank: 113,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 113,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 113,
    avg_round: 61,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 113,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 113,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 113,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 113,
    avg_round: 65,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 113,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 113,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 113,
    avg_round: 166,
    star_num: '4'
  }
];

export const Shiyu7_2 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 6.96,
    rank: 1,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 6.65,
    rank: 2,
    avg_round: 149,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 5.96,
    rank: 3,
    avg_round: 79,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 4.07,
    rank: 4,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 3.31,
    rank: 5,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 3.05,
    rank: 6,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 2.96,
    rank: 7,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 2.77,
    rank: 8,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 2.26,
    rank: 9,
    avg_round: 196,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 2.26,
    rank: 9,
    avg_round: 178,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.93,
    rank: 11,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 1.77,
    rank: 12,
    avg_round: 187,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 1.71,
    rank: 13,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 1.64,
    rank: 14,
    avg_round: 163,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 1.64,
    rank: 14,
    avg_round: 178,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 1.57,
    rank: 16,
    avg_round: 61,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 1.55,
    rank: 17,
    avg_round: 91,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 1.36,
    rank: 18,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 1.31,
    rank: 19,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 1.27,
    rank: 20,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 1.17,
    rank: 21,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 1.17,
    rank: 21,
    avg_round: 218,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.03,
    rank: 23,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.95,
    rank: 24,
    avg_round: 137,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.93,
    rank: 25,
    avg_round: 76,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.9,
    rank: 26,
    avg_round: 179,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.86,
    rank: 27,
    avg_round: 212,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.84,
    rank: 28,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.83,
    rank: 29,
    avg_round: 214,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.76,
    rank: 30,
    avg_round: 125,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.76,
    rank: 30,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.69,
    rank: 32,
    avg_round: 184,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.67,
    rank: 33,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.64,
    rank: 34,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.64,
    rank: 34,
    avg_round: 203,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.64,
    rank: 34,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.6,
    rank: 37,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.57,
    rank: 38,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.55,
    rank: 39,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.52,
    rank: 40,
    avg_round: 184,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.5,
    rank: 41,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.47,
    rank: 42,
    avg_round: 213,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.47,
    rank: 42,
    avg_round: 175,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.45,
    rank: 44,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.43,
    rank: 45,
    avg_round: 172,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.41,
    rank: 46,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.41,
    rank: 46,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.41,
    rank: 46,
    avg_round: 173,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.4,
    rank: 49,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.4,
    rank: 49,
    avg_round: 184,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.4,
    rank: 49,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.38,
    rank: 52,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.38,
    rank: 52,
    avg_round: 200,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.38,
    rank: 52,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.36,
    rank: 55,
    avg_round: 176,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.36,
    rank: 55,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.34,
    rank: 57,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.34,
    rank: 57,
    avg_round: 121,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.33,
    rank: 59,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.31,
    rank: 60,
    avg_round: 165,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.29,
    rank: 61,
    avg_round: 191,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.29,
    rank: 61,
    avg_round: 191,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.28,
    rank: 63,
    avg_round: 175,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.28,
    rank: 63,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.26,
    rank: 65,
    avg_round: 206,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.24,
    rank: 66,
    avg_round: 261,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.24,
    rank: 66,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.22,
    rank: 68,
    avg_round: 197,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.22,
    rank: 68,
    avg_round: 105,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.22,
    rank: 68,
    avg_round: 90,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 68,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.22,
    rank: 68,
    avg_round: 169,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.22,
    rank: 68,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.21,
    rank: 74,
    avg_round: 197,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 75,
    avg_round: 72,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'rina',
    app_rate: 0.19,
    rank: 75,
    avg_round: 165,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.19,
    rank: 75,
    avg_round: 191,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 75,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.19,
    rank: 75,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.19,
    rank: 75,
    avg_round: 209,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.19,
    rank: 75,
    avg_round: 95,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.17,
    rank: 82,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 83,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.16,
    rank: 83,
    avg_round: 198,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 83,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lycaon',
    app_rate: 0.16,
    rank: 83,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 83,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 83,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.16,
    rank: 83,
    avg_round: 197,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.14,
    rank: 90,
    avg_round: 153,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 90,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.14,
    rank: 90,
    avg_round: 239,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.14,
    rank: 90,
    avg_round: 90,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.14,
    rank: 90,
    avg_round: 109,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.14,
    rank: 90,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.14,
    rank: 90,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'yanagi',
    app_rate: 0.14,
    rank: 90,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'qingyi',
    app_rate: 0.12,
    rank: 98,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 98,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 98,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 98,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'soukaku',
    app_rate: 0.12,
    rank: 98,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 98,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.12,
    rank: 98,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 98,
    avg_round: 180,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 98,
    avg_round: 259,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.12,
    rank: 98,
    avg_round: 176,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'grace-howard',
    app_rate: 0.1,
    rank: 108,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.1,
    rank: 108,
    avg_round: 203,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 108,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 108,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 108,
    avg_round: 175,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 108,
    avg_round: 164,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 108,
    avg_round: 179,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.1,
    rank: 108,
    avg_round: 173,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'grace-howard',
    app_rate: 0.1,
    rank: 108,
    avg_round: 196,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.1,
    rank: 108,
    avg_round: 162,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'qingyi',
    app_rate: 0.1,
    rank: 108,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 108,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 108,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 108,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 122,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 122,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'anby-demara',
    app_rate: 0.09,
    rank: 122,
    avg_round: 155,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 122,
    avg_round: 159,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 122,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 122,
    avg_round: 221,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 122,
    avg_round: 176,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 122,
    avg_round: 259,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 122,
    avg_round: 176,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.09,
    rank: 122,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.09,
    rank: 122,
    avg_round: 245,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 122,
    avg_round: 296,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 134,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 134,
    avg_round: 162,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.07,
    rank: 134,
    avg_round: 168,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 134,
    avg_round: 234,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 134,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 134,
    avg_round: 180,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 134,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 134,
    avg_round: 205,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 134,
    avg_round: 107,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 134,
    avg_round: 174,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 134,
    avg_round: 187,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.07,
    rank: 134,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 134,
    avg_round: 186,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'anby-demara',
    app_rate: 0.07,
    rank: 134,
    avg_round: 208,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 134,
    avg_round: 200,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 149,
    avg_round: 167,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 149,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 149,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 149,
    avg_round: 171,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 149,
    avg_round: 276,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 149,
    avg_round: 91,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 149,
    avg_round: 157,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 149,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'grace-howard',
    app_rate: 0.05,
    rank: 149,
    avg_round: 178,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 149,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 149,
    avg_round: 156,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 149,
    avg_round: 198,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 149,
    avg_round: 186,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 149,
    avg_round: 180,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 149,
    avg_round: 238,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 149,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 149,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 149,
    avg_round: 188,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 149,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 149,
    avg_round: 260,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 149,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 149,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 149,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.05,
    rank: 149,
    avg_round: 260,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.05,
    rank: 149,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 149,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'koleda',
    app_rate: 0.05,
    rank: 149,
    avg_round: 213,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 149,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 149,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 149,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 149,
    avg_round: 164,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 149,
    avg_round: 105,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 149,
    avg_round: 290,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 149,
    avg_round: 202,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 149,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 149,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.05,
    rank: 149,
    avg_round: 156,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 149,
    avg_round: 210,
    star_num: '4'
  },
  {
    char_one: 'qingyi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 149,
    avg_round: 171,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 149,
    avg_round: 197,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 149,
    avg_round: 198,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 149,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 149,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 149,
    avg_round: 142,
    star_num: '4'
  }
];

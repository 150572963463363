export const Shiyu6_2 = [
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 11.87,
    rank: 1,
    avg_round: 74,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 11.06,
    rank: 2,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 6.8,
    rank: 3,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 5.18,
    rank: 4,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 4.58,
    rank: 5,
    avg_round: 109,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 4.53,
    rank: 6,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 3.21,
    rank: 7,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 3.19,
    rank: 8,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 2.15,
    rank: 9,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 2.14,
    rank: 10,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 1.99,
    rank: 11,
    avg_round: 87,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.97,
    rank: 12,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.67,
    rank: 13,
    avg_round: 69,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 1.52,
    rank: 14,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.37,
    rank: 15,
    avg_round: 74,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 1.06,
    rank: 16,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 1.01,
    rank: 17,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.94,
    rank: 18,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.88,
    rank: 19,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.83,
    rank: 20,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.79,
    rank: 21,
    avg_round: 107,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.66,
    rank: 22,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.63,
    rank: 23,
    avg_round: 77,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.63,
    rank: 23,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.63,
    rank: 23,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.61,
    rank: 26,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.6,
    rank: 27,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.58,
    rank: 28,
    avg_round: 113,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.53,
    rank: 29,
    avg_round: 67,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.51,
    rank: 30,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.48,
    rank: 31,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.46,
    rank: 32,
    avg_round: 91,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.43,
    rank: 33,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.43,
    rank: 33,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.41,
    rank: 35,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.41,
    rank: 35,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.41,
    rank: 35,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.4,
    rank: 38,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.38,
    rank: 39,
    avg_round: 92,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.36,
    rank: 40,
    avg_round: 71,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.35,
    rank: 41,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.33,
    rank: 42,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.33,
    rank: 42,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.33,
    rank: 42,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.33,
    rank: 42,
    avg_round: 75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.31,
    rank: 46,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.3,
    rank: 47,
    avg_round: 189,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.28,
    rank: 48,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.28,
    rank: 48,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.28,
    rank: 48,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.28,
    rank: 48,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.26,
    rank: 52,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.26,
    rank: 52,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.26,
    rank: 52,
    avg_round: 113,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.26,
    rank: 52,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.25,
    rank: 56,
    avg_round: 207,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.25,
    rank: 56,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.25,
    rank: 56,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 59,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.23,
    rank: 59,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 59,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.23,
    rank: 59,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.23,
    rank: 59,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.23,
    rank: 59,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.2,
    rank: 65,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.2,
    rank: 65,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.2,
    rank: 65,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.2,
    rank: 65,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.2,
    rank: 65,
    avg_round: 84,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.2,
    rank: 65,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.2,
    rank: 65,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.18,
    rank: 72,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.18,
    rank: 72,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.18,
    rank: 72,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.17,
    rank: 75,
    avg_round: 180,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.17,
    rank: 75,
    avg_round: 88,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.17,
    rank: 75,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.17,
    rank: 75,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 75,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 80,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 80,
    avg_round: 165,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 82,
    avg_round: 254,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 82,
    avg_round: 74,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 82,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.13,
    rank: 82,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'ben',
    app_rate: 0.12,
    rank: 86,
    avg_round: 206,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 86,
    avg_round: 66,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.12,
    rank: 86,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 86,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.12,
    rank: 86,
    avg_round: 113,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 86,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.12,
    rank: 86,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 93,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 93,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 93,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 93,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 93,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 93,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'seth',
    app_rate: 0.1,
    rank: 93,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 93,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.1,
    rank: 93,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 102,
    avg_round: 88,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 102,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 102,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 102,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 102,
    avg_round: 87,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.08,
    rank: 102,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.08,
    rank: 102,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 102,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 102,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 102,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 112,
    avg_round: 167,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'ben',
    app_rate: 0.07,
    rank: 112,
    avg_round: 133,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 112,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'ben',
    app_rate: 0.07,
    rank: 112,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 112,
    avg_round: 125,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 112,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'lucy',
    char_two: 'ben',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 112,
    avg_round: 161,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 112,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 112,
    avg_round: 85,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 112,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 112,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 112,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 112,
    avg_round: 171,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 112,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 112,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.07,
    rank: 112,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 112,
    avg_round: 149,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'ben',
    app_rate: 0.07,
    rank: 112,
    avg_round: 109,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 112,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 131,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 131,
    avg_round: 133,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 131,
    avg_round: 88,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 131,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 131,
    avg_round: 187,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 131,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 131,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 131,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 131,
    avg_round: 85,
    star_num: '4'
  },
  {
    char_one: 'koleda',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 131,
    avg_round: 183,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 131,
    avg_round: 57,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 131,
    avg_round: 78,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 131,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 131,
    avg_round: 191,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 131,
    avg_round: 78,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 131,
    avg_round: 181,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 131,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 131,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 131,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'burnice',
    app_rate: 0.05,
    rank: 131,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 131,
    avg_round: 105,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'qingyi',
    app_rate: 0.05,
    rank: 131,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 131,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.05,
    rank: 131,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 131,
    avg_round: 67,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 131,
    avg_round: 165,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 131,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 131,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 131,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 131,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 131,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 131,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 131,
    avg_round: 92,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 131,
    avg_round: 40,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.05,
    rank: 131,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 131,
    avg_round: 144,
    star_num: '4'
  }
];
